
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { ISequence } from "@/schemas/ISequence";
import { ISetting } from "@/schemas/ISetting";
import { validationMixin } from "vuelidate";
import {
  integer,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import { IActivity } from "@/schemas/IActivity";
import { ITipGroup } from "@/schemas/ITipGroup";
import { IInstructor } from "@/schemas/IUser";
import { AttendanceType, FeedbackType, Scope, UnitType } from "@/schemas/Enums";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import FeedbackSettingForm from "@/components/admin/entityForm/FeedbackSettingForm.vue";
import { infoTooltips } from "@/utils/literals/admin";

@Component({
  name: "SequenceForm",
  components: { FeedbackSettingForm },
  computed: {
    UnitType() {
      return UnitType;
    },
    FeedbackType() {
      return FeedbackType;
    },
  },
  mixins: [validationMixin],
  validations: {
    sequence: {
      title: {
        required,
        maxLength: maxLength(100),
        minLength: minLength(5),
      },
      description: {
        required,
        maxLength: maxLength(1000),
        minLength: minLength(10),
      },
      authorized_instructors: {},
      settings: {
        minLength: minLength(1),
        $each: {
          first_feedback: {},
          second_feedback: {},
          attendance_type: { required },
          single_session_time: {
            integer,
            maxLength: maxLength(3),
            minLength: minLength(1),
            required,
          },
          activity_id: { required },
          indiv_tips_id: {},
          group_tips_id: {},
          group_session_time: {
            integer,
            maxLength: maxLength(3),
            minLength: minLength(1),
            required,
          },
          text_only: {},
          tip_groups: {},
          feedback: {},
          feedback_details: {
            $each: {
              type: {},
              display_score: {},
              display_feedback_message: {},
            },
          },
          tip_overview: {},
          carousel_tips: {},
          carousel_tip_unit: {},
          carousel_display_time: { integer },
          carousel_occurrence: { integer },
          overview_tips_id: {},
        },
      },
    },
  },
})
export default class SequenceForm extends Vue {
  @Prop({ type: String, required: false, default: UFliterals.create })
  readonly submitText: string | undefined;
  @Prop({ type: Function, required: false })
  readonly dataHandler: CallableFunction | undefined;
  @Prop({ type: Boolean, required: false, default: false })
  readonly readOnly: boolean | undefined;
  @Prop({ type: Object, required: false })
  readonly initialSequence: ISequence | undefined;

  sequence: ISequence = { title: "", description: "", is_open: false };
  activities: IActivity[] = [];
  tipGroups: ITipGroup[] = [];
  instructors: IInstructor[] = [];
  displayParticipationSettings: { [index: number]: boolean } = {};
  displayPerformanceSettings: { [index: number]: boolean } = {};
  lang = "fr";

  created(): void {
    this.fetchRequiredData();
    if (this.initialSequence) {
      if (Object.keys(this.initialSequence).length) {
        this.sequence = { ...this.initialSequence };
      }
    }
  }

  private fetchRequiredData() {
    this.$store.dispatch(
      "user/fetchInstructors",
      (instructors: IInstructor[]) => (this.instructors = instructors)
    );
    if (!this.activities.length) {
      this.$store.dispatch("activity/fetchActivities", {
        next: (activities: IActivity[]) => (this.activities = activities),
      });
    }
    if (!this.tipGroups.length) {
      this.$store.dispatch("tipGroup/fetchTipGroups", {
        next: (tipGroups: ITipGroup[]) => (this.tipGroups = tipGroups),
      });
    }
  }

  @Watch("initialSequence")
  initialActivityChanged(val: ISequence): void {
    this.renewSequence(val);
  }

  get groupTipsSelectValues(): { value: string; text: string }[] {
    let selectValues: { value: string; text: string }[] = [];
    this.tipGroups?.forEach((tipGroup) => {
      if (tipGroup.id && tipGroup.scope === Scope.Group) {
        selectValues.push({ value: tipGroup.id, text: tipGroup.title });
      }
    });
    return selectValues;
  }

  get indivTipsSelectValues(): { value: string; text: string }[] {
    let selectValues: { value: string; text: string }[] = [];
    this.tipGroups?.forEach((tipGroup) => {
      if (tipGroup.id && tipGroup.scope === Scope.Individual) {
        selectValues.push({ value: tipGroup.id, text: tipGroup.title });
      }
    });
    return selectValues;
  }

  get addSettingButtonText(): string {
    return this.sequence.settings?.length
      ? this.getUFLabel("addOtherActivity")
      : this.getUFLabel("addActivity");
  }

  get activitiesSelectValues(): { value: string; text: string }[] {
    let selectValues: { value: string; text: string }[] = [];
    this.activities?.forEach((activity) => {
      if (activity.id) {
        selectValues.push({ value: activity.id, text: activity.title });
      }
    });
    return selectValues;
  }

  get attendanceSelectValues(): { value: string; text: string }[] {
    let selectValues: { value: string; text: string }[] = [];
    Object.values(AttendanceType).forEach((attendanceType) => {
      selectValues.push({
        value: attendanceType,
        text: this.getUFLabel(attendanceType),
      });
    });
    return selectValues;
  }

  get titleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.sequence.title?.$dirty) return errors;
    !this.$v.sequence.title.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(5));
    !this.$v.sequence.title.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(100));
    !this.$v.sequence.title.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getSingleSessionTimeErrors(index: number): string[] {
    const errors: string[] = [];
    if (
      !this.$v.sequence.settings?.$each.$iter[index]?.single_session_time
        ?.$dirty
    )
      return errors;
    !this.$v.sequence.settings?.$each.$iter[index]?.single_session_time
      .minLength && errors.push(UFFormValidationMessage.minLengthNum(1));
    !this.$v.sequence.settings?.$each.$iter[index]?.single_session_time
      .maxLength && errors.push(UFFormValidationMessage.maxLengthNum(3));
    !this.$v.sequence.settings?.$each.$iter[index]?.single_session_time
      .required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getAttendanceTypeErrors(index: number): string[] {
    const errors: string[] = [];
    if (!this.$v.sequence.settings?.$each.$iter[index]?.attendance_type?.$dirty)
      return errors;
    !this.$v.sequence.settings?.$each.$iter[index]?.attendance_type.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getActivityIdErrors(index: number): string[] {
    const errors: string[] = [];
    if (!this.$v.sequence.settings?.$each.$iter[index]?.activity_id?.$dirty)
      return errors;
    !this.$v.sequence.settings?.$each.$iter[index]?.activity_id.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getGroupTipsErrors(index: number): string[] {
    const errors: string[] = [];
    if (!this.$v.sequence.settings?.$each.$iter[index]?.group_tips_id?.$dirty)
      return errors;
    return errors;
  }

  getIndivTipsErrors(index: number): string[] {
    const errors: string[] = [];
    if (!this.$v.sequence.settings?.$each.$iter[index]?.indiv_tips_id?.$dirty)
      return errors;
    return errors;
  }

  getGroupSessionTimeErrors(index: number): string[] {
    const errors: string[] = [];
    if (
      !this.$v.sequence.settings?.$each.$iter[index]?.group_session_time?.$dirty
    )
      return errors;
    !this.$v.sequence.settings?.$each.$iter[index]?.group_session_time
      .minLength && errors.push(UFFormValidationMessage.minLengthNum(1));
    !this.$v.sequence.settings?.$each.$iter[index]?.group_session_time
      .maxLength && errors.push(UFFormValidationMessage.maxLengthNum(3));
    !this.$v.sequence.settings?.$each.$iter[index]?.group_session_time
      .required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get descriptionErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.sequence.description?.$dirty) return errors;
    !this.$v.sequence.description.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(10));
    !this.$v.sequence.description.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(1000));
    !this.$v.sequence.description.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  get instructorSelectOptions(): Record<string, string>[] {
    return this.instructors.map((instructor) => {
      return {
        text: instructor.username,
        value: instructor.id,
      };
    });
  }

  renewSequence(value: ISequence): void {
    this.sequence = Object.assign({}, value);
  }

  getUFLabel(key: string): string {
    return UFliterals[key];
  }

  getSettingNumber(index: number): number {
    return ++index;
  }

  addSetting(): void {
    let newSetting: ISetting = {
      activity_id: "",

      attendance_type: AttendanceType.Physical,
      single_session_time: 10,
      group_session_time: 30,
      text_only: false,
      tip_overview: false,
      feedback: true,
      feedback_details: [
        {
          type: FeedbackType.Performance,
          display_feedback_message: true,
        },
        { type: FeedbackType.Participation, display_feedback_message: true },
      ],
      carousel_tips: false,
      carousel_tip_unit: UnitType.DisplayTime,
    };
    if (!this.sequence.settings) {
      Vue.set(this.sequence, "settings", [newSetting]);
    } else {
      Vue.set(
        this.sequence.settings,
        this.sequence.settings?.length,
        newSetting
      );
    }
  }

  removeSetting(index: number): void {
    if (this.sequence.settings) {
      let elementToRemove = this.sequence.settings[index];
      if (elementToRemove) {
        if (elementToRemove.id) {
          // The element is already saved, so we'll have to remove it
          this.$store.dispatch("setting/deleteSetting", {
            id: elementToRemove.id,
            next: () => {
              this.sequence.settings?.splice(index, 1);
            },
          });
        } else {
          this.sequence.settings.splice(index, 1);
        }
      }
    }
  }

  clearOverviewTipId(index: number): void {
    if (this.sequence.settings) {
      Vue.set(this.sequence.settings[index], "overview_tips_id", "");
    }
  }

  handleSubmit(): void {
    this.$v.$touch();
    this.$nextTick(() => {
      this.scrollToFirstInvalidControl();
    });

    if (!this.$v.$invalid) {
      if (this.dataHandler) {
        this.dataHandler(this.sequence);
      }
    }
  }

  clear(): void {
    this.$v.$reset();
    this.sequence = { title: "", description: "", is_open: false };
  }

  onActivityChange(activityId: string, settingIndex: number): void {
    let selectedActivity = this.activities.find(
      (activity) => activity.id === activityId
    );
    if (this.sequence.settings) {
      if (this.sequence.settings[settingIndex]) {
        Vue.set(
          this.sequence.settings[settingIndex],
          "single_session_time",
          selectedActivity?.single_session_time
        );
        Vue.set(
          this.sequence.settings[settingIndex],
          "group_session_time",
          selectedActivity?.group_session_time
        );
      }
    }
  }

  scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement =
      document.getElementsByClassName("error--text");
    // FIXME
    console.log(firstInvalidControl);
    // firstInvalidControl[0].focus(); //without smooth behavior
  }

  getTooltipContent(key: string): string {
    return infoTooltips[this.lang][key];
  }
}
